import { ApiService } from "@/services/ApiService"
import { useToast } from 'vue-toastification'

const toast = useToast()

const state = {
    allUsers: [],
    isUserLoading: false,
    usersPaginatedData: null,
    isUserCreate: false,
    createdUserData: null,
    isUserDelete: false,
    user: null,
    isUserUpdate: false,
    updatedUserData: null,
    isCurrentLoading: false
}

const getters = {
    getUsers: state => state.allUsers,
    usersPaginatedData: state => state.usersPaginatedData,
    isUserLoading: state => state.isUserLoading,
    isCurrentLoading: state => state.isCurrentLoading,
    isUserCreate: state => state.isUserCreate,
    isUserUpdate: state => state.isUserUpdate,
    createdUserData: state => state.createdUserData,
    updatedUserData: state => state.updatedUserData,
    isUserDelete: state => state.isUserDelete,
    user: state => state.user,
}

const mutations = {
    GET_ALL_USERS(state, allUsers) {
        state.allUsers = allUsers
    },
    SET_USER_PAGINATED: (state, usersPaginatedData) => {
        state.usersPaginatedData = usersPaginatedData
    },
    SET_LOADING(state, isUserLoading) {
        state.isUserLoading = isUserLoading
    },
    SET_CURRENT_LOADING(state, payload) {
        state.isCurrentLoading = payload
    },
    SET_USER_IS_CREATE(state, isUserCreate) {
        state.isUserCreate = isUserCreate
    },
    SET_CREATE_USER: (state, user) => {
        state.allUsers.unshift(user)
        state.createdUserData = user;
    },
    SET_UPDATE_USER: (state, user) => {
        state.allUsers.unshift(user)
        state.updatedUserData = user;
    },
    SET_USER_IS_DELETE(state, isUserDelete) {
        state.isUserDelete = isUserDelete
    },
    SET_DELETE_USER: (state, id) => {
        state.usersPaginatedData.data.filter(x => x.id !== id);
    },
    SET_USER_DETAILS: (state, user) => {
        state.user = user
    },
    SET_USER_IS_UPDATE(state, isUserUpdate) {
        state.isUserUpdate = isUserUpdate
    },
}

const actions = {
    async getAllUsers({ commit }, query = null) {
        let current_page = 1;
        let search = '';
        let records = 10
        if (query !== null) {
            current_page = query?.current_page || 1;
            search = query?.search || '';
            records = query?.records || 10
        }
        commit('SET_LOADING', true);
        let url = '/users'
        if (search === null) {
            url = `${url}?current_page=${current_page}`;
        } else {
            url = `${url}?search=${search}&current_page=${current_page}&records=${records}`
        }
        await ApiService.get(url)
            .then(res => {
                const users = res.data.data.data;
                commit('GET_ALL_USERS', users);
                commit('SET_USER_PAGINATED', res.data.data);
                commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_LOADING', false);
            });
    },

    async fetchUserDetails({ commit }, id) {
        commit('SET_LOADING', true);
        await ApiService.get(`users/${id}`)
            .then(res => {
                commit('SET_USER_DETAILS', res.data.data);
                commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_LOADING', false);
            });
    },

    async createUser({ commit }, payload) {
        commit('SET_USER_IS_CREATE', true);
        commit('SET_CURRENT_LOADING', true);
        await ApiService.post('/users', payload)
            .then(res => {
                commit('SET_CREATE_USER', res.data.data);
                commit('SET_USER_IS_CREATE', false);
                commit('SET_CURRENT_LOADING', false);
                toast.success("User Created Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                commit('SET_USER_IS_CREATE', false);
                commit('SET_CURRENT_LOADING', false);
                toast.error("Unable To Add User!", { timeout: 2000 })
            });
    },

    async updateUser({ commit }, user) {
        commit('SET_USER_IS_UPDATE', true);
        commit('SET_CURRENT_LOADING', true);
        await ApiService.put(`/users/${user.id}`, user)
            .then(res => {
                commit('SET_UPDATE_USER', res.data.data);
                commit('SET_USER_IS_UPDATE', false);
                commit('SET_CURRENT_LOADING', false);
                toast.success("User Updated Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                commit('SET_USER_IS_UPDATE', false);
                commit('SET_CURRENT_LOADING', false);
                toast.error("Unable To Update User!", { timeout: 2000 })
            });
    },

    async deleteUser({ commit }, id) {
        commit('SET_USER_IS_DELETE', true);
        await ApiService.delete(`/users/${id}`)
            .then(res => {
                commit('SET_DELETE_USER', res.data.data.id);
                commit('SET_USER_IS_DELETE', false);
                toast.success("User Deleted Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                commit('SET_USER_IS_DELETE', false);
                toast.error("Unable To Delete User!", { timeout: 2000 })
            });
    },

    async blockUser({ commit }, id) {
        commit('SET_USER_IS_DELETE', true);
        await ApiService.get(`/block/${id}`)
            .then(res => {
                commit('SET_DELETE_USER', res.data.data.id);
                commit('SET_USER_IS_DELETE', false);
                toast.success(res.data.message, { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                commit('SET_USER_IS_DELETE', false);
                toast.error("Unable To Block User!", { timeout: 2000 })
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}