<template>
  <!-- header -->
  <header class="gbl__header">
    <nav class="navbar navbar-expand-sm p-0 noPrint" style="background-color:#404040">
      <div class="container-fluid gbl__wrapper">
        <a class="navbar-brand gbl__header-logo" href="#" title="Gold Bank London">
          <img src="@/assets/images/Logo/logo-circle.svg" alt="Logo" style="height: 50px;" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 gbl__header-navlist" style=" margin-left: -90px;">
            <li v-for="(item, index) in getMenu.value" :key="index" class="nav-item gbl__header-navitem"
              @click="goToTab(item)">
              <a class="gbl__header-navlink" :class="{ active: getMenuPath == item.path }"
                style="cursor: pointer; border-radius: 8px; padding: 5px 10px 5px 10px;" aria-current="page">{{ item.name
                }}</a>
            </li>
          </ul>
        </div>
        <div style="width: 25%;">
          <input v-model="query.search" @input="debounceTransactionFilter" type="text" class="search-container"
            placeholder="Search.." style="background-color:#E0E0E0;">
        </div>

        <div class="gbl__header-right d-flex align-items-center ">
          <div class="dropdown gbl__dropdownbox">
            <button class="
                d-flex
                align-items-center
                gbl__dropdown-btn gbl__dropdown--light
                dropdown-toggle
              " type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="gbl__dropdown-iconspan"><img src="@/assets/images/user.svg" alt="user_img" /></span>
              <span class="gbl__dropdown-text">{{ user.name }}</span>
              <span><img src="@/assets/images/arrow-down-fill.svg" alt="arrow_icon" /></span>
            </button>
            <ul class="
                dropdown-menu
                gbl__dropdown-menu gbl__dropdown-menu--space
                dropdown-menu-end
              " aria-labelledby="navbarDropdown">
              <li class="gbl__dropdown-item" @click="$router.push({ path: '/profile' })">
                <a class="dropdown-item gbl__dropdown-link" style="cursor: pointer">Setting</a>
              </li>
              <li class="gbl__dropdown-item" @click="logoutUser">
                <a class="dropdown-item gbl__dropdown-link" style="cursor: pointer">Logout</a>
              </li>
            </ul>
          </div>

          <!-- notification modal -->
          <NotificationModal />
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import NotificationModal from "@/components/modal/NotificationModal";

// data properties
const store = useStore();
const router = useRouter();
const route = useRoute();

let query = reactive({
  current_page: 1,
  search: "",
  records: 100,
});

let user = JSON.parse(localStorage.getItem('user'))
const menu = ref([
  {
    path: "/",
    name: "Dashboard",
  },
  {
    path: "/customers",
    name: "Customers",
  },
  {
    path: "/reports",
    name: "Reports",
  },
  // {
  //   path: "/users",
  //   name: "Users",
  // },
  {
    path: "/refine",
    name: "Refine",
  },
]);

const menu2 = ref([
  {
    path: "/",
    name: "Dashboard",
  },
  {
    path: "/customers",
    name: "Customers",
  },
  {
    path: "/reports",
    name: "Reports",
  },
  {
    path: "/refine",
    name: "Refine",
  },

]);

// methods
const getSearchTransactionsList = async () => {
  await store.dispatch("getAllSearchTransactions", query);
};

const debounceTransactionFilter = _.debounce(async function () {
  if (query.search.length > 1 || router.path != "/search") {
    router.push({ path: "/search" })
  }
  await getSearchTransactionsList();

}, 500);

// computed
const getMenuPath = computed(() => {
  return route.path
})


const getMenu = computed(() => {
  if (user.type == "sub-admin") {
    return menu2
  }
  return menu
})

// methods
const logoutUser = async () => {
  try {
    await store.dispatch("logout");
    router.push({ path: "/login" });
  } catch (error) {
    console.log(error);
  }
};
const goToTab = async (item) => {
  router.push({ path: item.path });
};
</script>

<style>
.search-container {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  padding: min(max(6px, 0.495vw), 9.5px) 12px;
}

.gbl__header-navlink.active {
  background-color: #04AA6D;
}

.gbl__header-navlist {
  margin-left: 0px;
}
</style>